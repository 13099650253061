var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.menus),function(menu,index){return [(menu.subMenu)?_c('li',{key:index,staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(menu.showCategory)?[_c('a',{staticClass:"menu-link menu-toggle"},[_c('span',{staticClass:"menu-icon svg-icon svg-icon-xl"},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":menu.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.name))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._l((menu.subMenu),function(menuItem,i){return [(menuItem.isShow)?_c('router-link',{key:i,staticClass:"menu-link menu-toggle",attrs:{"to":{ name: menuItem.to }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-line"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menuItem.name))])])])]}}],null,true)}):_vm._e()]})],2)])]:_vm._e()],2):_c('router-link',{key:index,staticClass:"menu-link menu-toggle",attrs:{"to":{ name: menu.to }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(menu.isShow)?[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon svg-icon svg-icon-xl"},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":menu.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.name))])])]:_vm._e()],2)]}}],null,true)})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }